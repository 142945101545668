
import HomeMedicalPublicRelationsInfoSections from '@/components/commons/organism/HomeMedicalPublicRelationsInfoSections.vue';
import HospitalPublicRelationsInfoSections from '@/components/commons/organism/HospitalPublicRelationsInfoSections.vue';
import PublicRelationsInformationDialog from '@/components/commons/organism/PublicRelationsInformationDialog.vue';
import VisitingNursePublicRelationsInfoSections from '@/components/commons/organism/VisitingNursePublicRelationsInfoSections.vue';
import usePublicRelations from '@/components/molecules/hooks/usePublicRelations';
import { Timestamp } from '@firebase/firestore';
import { Loader } from '@googlemaps/js-api-loader';
import { mapGetters } from 'vuex';
import GeolocationDialog from '~/components/Atoms/GeolocationDialog';
import { ASSET_TYPE } from '~/components/settings/variables';
import { DISPLAY_FAX_DESK, DISPLAY_PHONE_DESK } from '~/constants/publicRelations.constants.ts';
import CloseIcon from '~/static/img/icons/info-close.svg?inline';
import { CONNECT_MODE_MAP } from '../settings/variables';
import { checkMappingFacilityId } from '../utils/facilityMapping';
import ConnectdetailDialog from './ConnectDetailDialog';
import ConnectprDialog from './ConnectPrDialog';

const mapSettings = require('../settings/map'); // google map

const MIN_ZOOM = mapSettings.minZoom; // 広く表示する最大値
const MAX_ZOOM = mapSettings.maxZoom;
const SEARCH_HIT_LIMIT = mapSettings.searchHitLimit;
const ADMIN_SEARCH_HIT_LIMIT = mapSettings.adminSearchHitLimit;
const DEFAULT_ZOOM = mapSettings.defaultZoom;

const MARKER_PIN_ICON = '/img/location.svg';
const CONNECT_MARKER_PIN_ICON = '/img/location_connect.svg';
const SENDER_CONNECT_MARKER_PIN_ICON = '/img/location_sender_connect.svg';
const BOTH_CONNECT_MARKER_PIN_ICON = '/img/location_both_connect.svg';

const { isPublicRelationsEnabled } = usePublicRelations();

// マーカーのスタイルを指定
const styleFeature = (google) => {
  return {
    icon: {
      url: MARKER_PIN_ICON,
      scaledSize: new google.maps.Size(48, 48)
    },
    optimized: false,
    zindex: 10
  };
};

let infoActionWindowArray = []; // アクションウィンドウの排他制御用
let actionTimerList = []; // mapのinfo-boxのclose-event用
let beforeHighlightElementId = ''; // 一覧ハイライト用

export default {
  name: 'googleMap',
  data() {
    return {
      google: null,
      map: null,
      isFirstViews: true, // zoom制御で利用する初回表示かの判断フラグ
      currentZoom: DEFAULT_ZOOM,
      areaCircle: [],
      circleRadius: 2000, // default 2000m
      enablePrintButton: true, // 印刷ボタンの表示
      searchAlartbar: false, //　施設表示上限を超えたときのアラート
      searchHitlimit: SEARCH_HIT_LIMIT,
      markers: [],
      connectPrDialog: false,
      connectdetailDialog: false,
      connectEmptyBedInfoDialog: false,
      promotionItem: { properties: '', analyticsUserId: '' },
      hoverCompany: '',
      lastId: '',
      searchEventCheck: false,
      settingCenterLocation: {}, // ユーザが設定した位置情報
      geolocationDialog: false, // 地図情報ダイアログ
      showGeolocationtips: false, // tooltip表示用
      publicRelationsInfoBaseProps: {},
      hospitalPublicRelationsInfoSectionsProps: {
        weeklyAcceptablePersonPerDay: {},
        enrollmentType: {},
        acceptablePatientType: [],
        specializedInformationDescription: '',
        supportAreaDescription: '',
        otherSupplementation: ''
      },
      visitingNursePublicRelationsInfoSectionsProps: {
        weeklyAcceptablePersonPerDay: {},
        enrollmentType: {},
        acceptablePatientType: [],
        specializedInformationDescription: '',
        supportAreaDescription: '',
        otherSupplementation: ''
      },
      homeMedicalPublicRelationsInfoSectionsProps: {
        supportAreaDescription: '',
        specializedFieldDescription: '',
        partnerMedicalInstitutionDescription: '',
        acceptablePatientType: [],
        acceptablePatientDescription: '',
        annexationOfHomeNursing: false
      },
      markerLibrary: null // マーカーライブラリをキャッシュするための変数
    };
  },
  props: {
    publicRelationsData: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  components: {
    ConnectprDialog,
    ConnectdetailDialog,
    GeolocationDialog,
    CloseIcon,
    PublicRelationsInformationDialog,
    HospitalPublicRelationsInfoSections,
    VisitingNursePublicRelationsInfoSections,
    HomeMedicalPublicRelationsInfoSections
  },
  watch: {
    searchMapEvent: {
      // 検索した時
      handler: function () {
        if (this.searchMapEvent === true) {
          this.placeZoomAndPanTo();
        }
        this.searchEventCheck = false;
        this.$store.commit('locationSearch', this.searchEventCheck);
      },
      deep: true
    },
    boundsStation: {
      handler: function (val2, oldVal2) {
        this.refreshMarker();
        this.searchAlart();
      },
      deep: true
    },
    selectedRadio: {
      handler: function (val3, oldVal3) {
        this.placeZoomAndPanTo();
        // 包括の時は印刷ボタンを非表示
        if (this.selectedRadio === '包括') {
          this.enablePrintButton = false;
        } else {
          this.enablePrintButton = true;
        }
      },
      deep: true
    },
    circleRadius: {
      handler: function (val4, oldVal4) {
        this.makeCenterCircle();
      }
    },
    markerId: {
      handler: function (id, oldId) {
        this.boundMarker(id);
        // 左側のリストに触れた時はリセットする
        this.lastId = '';
      },
      deep: true
    }
  },
  methods: {
    // マーカーライブラリを取得するメソッドを追加
    async getMarkerLibrary() {
      if (!this.markerLibrary) {
        this.markerLibrary = await google.maps.importLibrary('marker');
      }
      return this.markerLibrary;
    },
    initmap: async function () {
      try {
        // ユーザが設定した緯度経緯の情報を取得する
        if (localStorage.getItem('chousei-kun-setLocation')) {
          this.settingCenterLocation = JSON.parse(localStorage.getItem('chousei-kun-setLocation'));
          // 設定した緯度経緯がある場合は位置情報変える
          if (Object.keys(this.settingCenterLocation).length) {
            // ユーザが設定した位置情報をstoreに保存する
            this.$store.commit('localUpdate', this.settingCenterLocation);
          }
        }
      } catch (e) {
        this.$sentry.captureException(e);
      }

      const CAREBOOK_SEARCH_MAP_STYLE_ID = '78c257e071a1b966';
      // MAPの表示設定
      const options = (google) => {
        return {
          mapId: CAREBOOK_SEARCH_MAP_STYLE_ID,
          center: this.currentLocation,
          zoom: this.currentZoom,
          mapTypeControl: false,
          keyboardShortcuts: false,
          panControl: false,
          zoomControl: true,
          zoomControlOptions: {
            position: google.maps.ControlPosition.RIGHT_CENTER
          },
          streetViewControl: false,
          fullscreenControl: false,
          clickableIcons: false,
          minZoom: MIN_ZOOM, // 地図の表示エリア広くなる
          maxZoom: MAX_ZOOM // 地図の表示エリア狭くなる,
        };
      };

      // MAP描画
      const loader = new Loader({
        apiKey: mapSettings.apiKey,
        version: 'quarterly',
        libraries: ['places']
      });

      try {
        const google = await loader.load();
        this.google = google;
        this.map = new google.maps.Map(document.getElementById('map'), options(this.google));
        this.map.addListener('idle', () => {
          this.$store.commit('isMapFixed', true);
        });

        if (!this.disabledSearch) {
          this.map.addListener(
            'bounds_changed',
            () => {
              this.$store.commit('isMapFixed', false);
              this.currentZoom = this.map.zoom; // MAPのZOOMを保存
              // Vuexにbounds情報をいれる
              let bounds = this.map.getBounds();
              let northEastLatLng = bounds.getNorthEast(); // 左上
              let southWestLatLng = bounds.getSouthWest(); // 右下
              let currentBounds = {
                northEastLatLng: northEastLatLng,
                southWestLatLng: southWestLatLng,
                center: this.map.center
              };
              // 現在位置情報(四隅)をVuexへ保存
              this.$store.commit('boundsUpdate', currentBounds);
              // 地図上の動きがあった時は、ふきだし描画情報をリセットする
              this.lastId = '';
              // 表示エリアに限定して一覧表示を更新
              // 最初の1回だけに限って、件数が少ない時自動でズームアウトする
              // 限定している理由は、あえて1件をズームして見ようとした時に
              if (this.boundsStation.length < 5 && this.isFirstViews) {
                // 最大表示エリアになるまで、表示エリアを広げていく
                if (this.currentZoom > MIN_ZOOM + 1) {
                  this.currentZoom = this.currentZoom - 1; // 1段階広くする
                  this.placeZoomAndPanTo();
                  this.isFirstViews = false;
                }
                return;
              }

              // 表示するデータがある場合は何もしない
              if (this.boundsStation.length) {
                return;
              }

              // データなしデザインの表示
              // 最大表示エリアになるまで、表示エリアを広げていく
              if (this.currentZoom > MIN_ZOOM + 1) {
                this.currentZoom = this.currentZoom - 1; // 1段階広くする
                this.placeZoomAndPanTo();
              }
            },
            { capture: false, passive: true }
          );
        }
      } catch (e) {
        console.log(e.message);
      }
    },
    /**
     * マーカーのリフレッシュ
     */
    refreshMarker: function () {
      this.lastId = '';
      // マーカー削除
      this.clearMarkers();
      this.map.data.setMap(null);
      this.map.data = new this.google.maps.Data({ map: this.map });
      // データレイヤのスタイルを指定
      this.map.data.setStyle(styleFeature);
      // infoActionboxを閉じる
      this.clearInfoActionWindows();
      // 同心円を描画
      this.makeCenterCircle();
      // // マーカー描画
      this.editMarkers();
    },
    /**
     * 作成:マーカの描画
     */
    editMarkers: async function () {
      const { AdvancedMarkerElement } = await this.getMarkerLibrary();

      // 全国のマーカーを読み込んでおくのは、処理が重くなるのでUXとして悪いのでやめること
      // 絞り込んだデータを、データレイヤーに追加
      for (const item of this.boundsStation) {
        const markerContent = this.createMarkerContent(item);

        const marker = new AdvancedMarkerElement({
          position: {
            lat: item.geometry.coordinates[1],
            lng: item.geometry.coordinates[0]
          },
          map: this.map,
          content: markerContent,
          gmpDraggable: false,
          collisionBehavior: google.maps.CollisionBehavior.REQUIRED
        });
        marker.facilityId = item.properties.id;

        this.setupMarkerEventListeners(marker, item);
        this.markers.push(marker);
      }

      this.setupListingDisplay();
    },

    /**
     * マーカーのコンテンツ要素を作成する
     */
    createMarkerContent: function (item) {
      const markerContent = document.createElement('div');
      markerContent.className = 'marker-with-label';
      markerContent.style.position = 'relative';
      markerContent.style.textAlign = 'center';
      markerContent.style.top = '3px';

      // SVG画像要素を作成
      const pinContainer = document.createElement('div');
      const svgImg = document.createElement('img');
      svgImg.src = this.makeConnectPinIcon(item);
      svgImg.style.width = '46px'; // SVGの元のサイズ
      svgImg.style.height = '46px';
      pinContainer.appendChild(svgImg);
      markerContent.appendChild(pinContainer);

      // テキストラベルを追加
      const label = document.createElement('div');
      label.textContent = item.properties.company_name;
      label.style.fontSize = '11px';
      label.style.fontWeight = 'bold';
      label.style.backgroundColor = 'rgba(255, 255, 255, 1)';
      label.style.padding = '3px';
      label.style.borderRadius = '6px';
      label.style.position = 'absolute';
      label.style.whiteSpace = 'nowrap';
      label.style.top = '45px';
      label.style.left = '-5px';

      markerContent.appendChild(label);

      return markerContent;
    },

    /**
     * マーカーにイベントリスナーを設定する
     */
    setupMarkerEventListeners: function (marker, item) {
      // マーカーにマウスオーバー / タッチしたとき
      const targetEvent = 'mouseover';
      marker.element.addEventListener(
        targetEvent,
        () => {
          this.handleMarkerMouseOver(item);
        },
        { capture: false, passive: true }
      );

      // マーカーから外れたとき
      marker.element.addEventListener(
        'mouseout',
        () => {
          this.handleMarkerMouseOut();
        },
        { capture: false, passive: true }
      );
    },

    /**
     * マーカーのマウスオーバー時の処理
     */
    handleMarkerMouseOver: function (item) {
      // 左リストのハイライトを制御
      const targetElement = document.getElementById(item.properties.id);
      try {
        targetElement.scrollIntoView();
        targetElement.setAttribute('class', 'station-list__selected');
      } catch (e) {
        // switchを連打で切り替えた時に発生しやすい。UIに影響ないので無視して良い
      }

      // 1つ前のスタイルをもとに戻す
      if (beforeHighlightElementId !== '' && beforeHighlightElementId !== item.properties.id) {
        try {
          document
            .getElementById(beforeHighlightElementId)
            .setAttribute('class', 'station-list__normal');
        } catch (e) {
          // switchを連打で切り替えた時に発生しやすい。UIに影響ないので無視して良い
        }
      }
      beforeHighlightElementId = item.properties.id;

      // バウンド処理
      this.$store.commit('mapMarkerid', item.properties.id);

      // 前に出したふきだしが同じ場合は表示処理行わない（再表示防止）
      if (beforeHighlightElementId !== this.lastId) {
        // ある程度間隔を置いて表示させる
        setTimeout(() => {
          // ふきだしinfowindowの表示
          this.generateMapInfoBox(item);
        }, 200);
      }
    },

    /**
     * マーカーのマウスアウト時の処理
     */
    handleMarkerMouseOut: function () {
      // 10秒立ったら非表示させるが
      // 他のマーカーにカーソルあたったら
      // 非表示イベントはキャンセルさせるためにactionTimerListに入れておく
      actionTimerList.push(
        setTimeout(() => {
          this.clearInfoActionWindows();
        }, 10000)
      );
    },

    /**
     * 一覧表示の設定
     */
    setupListingDisplay: function () {
      try {
        // 一覧を表示
        document.getElementById('listing').setAttribute('style', 'display:block;');
        // リスト内の表示を一番上に移動
        document.getElementById('result-lists').firstElementChild.scrollIntoView();
      } catch (e) {
        // switchを連打で切り替えた時に発生しやすい。UIに影響ないので無視して良い
      }
    },
    makeConnectPinIcon: function (item) {
      let pinIcon;
      // 管理者モードの時だけ詳細にマーカーの色分けをする
      if (this.enableAdminMode && this.isLogin) {
        switch (item.properties.connect_mode) {
          case 'receiver': // 受け側
            pinIcon = CONNECT_MARKER_PIN_ICON;
            return pinIcon;
          case 'sender': // 出し側
            pinIcon = SENDER_CONNECT_MARKER_PIN_ICON;
            return pinIcon;
          case 'both': // 出し側と受け側の両方の場合は受け側の表記にしておく
            pinIcon = BOTH_CONNECT_MARKER_PIN_ICON;
            return pinIcon;
          default:
            pinIcon = MARKER_PIN_ICON;
            return pinIcon;
        }
      }

      pinIcon =
        item.properties.connect_mode && this.isLogin ? CONNECT_MARKER_PIN_ICON : MARKER_PIN_ICON;
      return pinIcon;
    },
    clearMarkers: function () {
      this.markers.forEach(function (marker) {
        marker.setMap(null);
      });
      this.markers = [];
    },
    makeCenterCircle: async function () {
      // 初期化
      this.areaCircle.forEach(function (circle) {
        circle.setMap(null);
      });
      this.areaCircle = [];

      const { AdvancedMarkerElement } = await this.getMarkerLibrary();

      let marker = new AdvancedMarkerElement({
        map: this.map,
        position: this.currentLocation,
        draggable: false,
        zIndex: 100
      });

      this.markers.push(marker);

      // 円を書く
      let circle = new this.google.maps.Circle({
        strokeColor: '#5ECBCC',
        strokeOpacity: 1,
        strokeWeight: 2,
        fillColor: '#5ECBCC',
        fillOpacity: 0.2,
        map: this.map,
        center: this.currentLocation,
        zindex: 1,
        radius: Number(this.circleRadius) // m単位の半径
      });

      this.areaCircle.push(circle);
    },
    /**
     * 表示エリアが変わったときの処理
     */
    placeZoomAndPanTo: function () {
      // 中心位置を変更すれば後は'bounds_changed'イベントリスナに登録した処理に任せる
      try {
        this.map.setZoom(this.currentZoom);
        this.map.panTo(this.currentLocation);
      } catch (e) {
        // なにもしない。sentryにも通知しない
      }
    },
    clearInfoActionWindows: function () {
      // 古いinfoboxを閉じる
      infoActionWindowArray.forEach((infowindow) => {
        infowindow.close();
      });
      infoActionWindowArray = [];
    },
    generateMapInfoBox: function (item) {
      this.clearInfoActionWindows();
      // 非表示にするタイマーを初期化する
      actionTimerList.forEach((timer) => {
        clearTimeout(timer);
      });

      const googleMaprouteElement = this.makeGoogleMapRouteUrl(item);
      const mapActionCloseElement = this.makeCloseButton(item);
      const homepageLinkElement = this.makeHomepageLink(item);
      const connectMessageElement = this.makeConnectMessage(item);
      const emptyBedinfoElement = this.makeEmptyBedinfoMessage(item);
      const contentMessage = connectMessageElement
        ? // コネクト導入(ホームページ、ルート、コネクト導線あり)DOM生成
          `
            <div class="map-action-window">
              ${mapActionCloseElement}
              <div class="map-action-window-title-connect">
                <div class="map-action-window-title-sentence">
                  <div class="map-action-window-company">
                    ${item.properties.company_name}
                  </div>
                </div>
                ${connectMessageElement}
                ${emptyBedinfoElement}
              </div>
              <div class="map-action-window-contain">
                ${homepageLinkElement}
                ${googleMaprouteElement}
              </div>
            </div>
          `
        : // コネクト未導入(ホームページ、ルート)DOM生成
          `
            <div class="map-action-window">
              ${mapActionCloseElement}
              <div class="map-action-window-title">
                <div class="map-action-window-company">
                  ${item.properties.company_name}
                </div>
              </div>
              <div class="map-action-window-contain">
                ${homepageLinkElement}
                ${googleMaprouteElement}
              </div>
            </div>
          `;
      // infoboxのオプション
      const infoboxOptions = {
        content: contentMessage, // 生成したDOMを割り当てる
        disableAutoPan: true, // 必須。これしないと再帰的にmapを描画し続ける
        pixelOffset: new this.google.maps.Size(0, -35), // オフセット値
        position: this.setLatlng(item)
      };

      setTimeout(() => {
        this.clearInfoActionWindows();
        const infowindow = new this.google.maps.InfoWindow(infoboxOptions);
        infowindow.open({ map: this.map, shouldFocus: false });
        infoActionWindowArray.push(infowindow);
      }, 100);

      // ふきだし表示した施設IDを保存
      this.lastId = item.properties.id;

      // ふきだしのボタンが押された時の処理
      try {
        // コネクト導入施設可否判定
        if (item.properties.connect_mode && this.isLogin && this.myConnectStatus) {
          // DOMがないとエラーになりダイアログが出ないためsetTimeoutを使用
          // 打診相談用(コネクト利用病院)
          setTimeout(() => {
            const closeButton = document.getElementById(`close-${item.properties.id}`);
            const askButton = document.getElementById(`ask-connect-${item.properties.id}`);
            const emptyBedButton = document.getElementById(`empty-bedinfo-${item.properties.id}`);
            if (askButton) {
              document.getElementById(`ask-connect-${item.properties.id}`).addEventListener(
                'click',
                () => {
                  this.openConnectdetailDialog(item);
                },
                { capture: false, passive: true }
              );
            }
            if (emptyBedButton) {
              document.getElementById(`empty-bedinfo-${item.properties.id}`).addEventListener(
                'click',
                () => {
                  this.filterFacilityPublicRelationsData(item);
                },
                { capture: false, passive: true }
              );
            }
            if (closeButton) {
              document.getElementById(`close-${item.properties.id}`).addEventListener(
                'click',
                () => {
                  this.clearInfoActionWindows();
                  this.lastId = '';
                  this.beforeHighlightElementId = '';
                  this.$store.commit('mapMarkerid', '');
                },
                { capture: false, passive: true }
              );
            }
          }, 200);
        } else {
          // PR用
          setTimeout(() => {
            const closeButton = document.getElementById(`close-${item.properties.id}`);
            const askButton = document.getElementById(`ask-web-${item.properties.id}`);
            if (askButton !== null) {
              document.getElementById(`ask-web-${item.properties.id}`).addEventListener(
                'click',
                () => {
                  this.openConnectprDialog(item);
                },
                { capture: false, passive: true }
              );
            }
            if (closeButton) {
              document.getElementById(`close-${item.properties.id}`).addEventListener(
                'click',
                () => {
                  this.clearInfoActionWindows();
                  this.lastId = '';
                  this.beforeHighlightElementId = '';
                  this.$store.commit('mapMarkerid', '');
                },
                { capture: false, passive: true }
              );
            }
          }, 200);
        }
        // 公式サイトをクリック
        setTimeout(() => {
          const homepageButton = document.getElementById(`web-${item.properties.id}`);
          if (homepageButton !== null) {
            document.getElementById(`web-${item.properties.id}`).addEventListener(
              'click',
              () => {
                this.$analytics({
                  // send Google Analytics
                  eventCategory: 'map側公式サイトクリック',
                  eventAction: `(map側公式サイトクリック)${item.properties.company_name}`,
                  eventLabel: this.makeAnalyticsLabel()
                });
              },
              { capture: false, passive: true }
            );
          }
        }, 200);
        // ルートを探すをクリック
        setTimeout(() => {
          const routeButton = document.getElementById(`route-${item.properties.id}`);
          if (routeButton !== null) {
            document.getElementById(`route-${item.properties.id}`).addEventListener(
              'click',
              () => {
                this.$analytics({
                  // send Google Analytics
                  eventCategory: 'map側ルートを探すクリック',
                  eventAction: `(map側ルートを探すクリック)${item.properties.company_name}`,
                  eventLabel: this.makeAnalyticsLabel()
                });
              },
              { capture: false, passive: true }
            );
          }
        }, 200);
      } catch (e) {
        this.$sentry.captureException(e);
      }
    },
    makeGoogleMapRouteUrl(item) {
      const targetLatlng = this.setLatlng(item);
      const googleRouteUrl = `https://www.google.co.jp/maps/dir//${targetLatlng.lat},${targetLatlng.lng}/@${targetLatlng.lat},${targetLatlng.lng},17z`;

      return `
        <div class="map-action-window-route">
          <a href="${googleRouteUrl}" rel="noopener" target="_blank" id="route-${item.properties.id}">
            <div><img
              src="/img/icons/info-route-red.svg"
              alt="info-homepage"
              class="map-action-window__route-icon"
            />ルートを探す</div>
          </a>
        </div>
      `;
    },
    makeCloseButton(item) {
      return `
        <div class="map-action-window-close" id="close-${item.properties.id}">
          <img src="/img/icons/info-bubble-close.svg" alt="info-action-window-close"
                class="map-action-window__close-icon" />
        </div>
      `;
    },
    makeHomepageLink(item) {
      try {
        if (item.properties.url && item.properties.url !== '不明') {
          // urlがあるときだけ、リンク表示用のタグを追加する
          // パラメータ付きリンクのときは、utm_sourceタグを追加しない
          // 遷移先でエラーになるリスクが有る(himawari.metro.tokyo.jpは確実にエラーになる)
          return `
          <div class="map-action-window-url">
            <a href="${item.properties.url}" rel="noopener" target="_blank" id="web-${item.properties.id}">
            <div>
              <img
                src="/img/icons/info-homepage-red.svg"
                alt="info-homepage"
                class="map-action-window__enabled"/>
                ホームページ
            </div>
            </a>
          </div>`;
        } else {
          return `
          <div class="map-action-window-url">
            <div id="web-${item.properties.id}">
              <img
                src="/img/icons/info-homepage.svg"
                alt="info-homepage"
                class="map-action-window__disenabled"/>
                ホームページなし
            </div>
          </div>`;
        }
      } catch {}
    },
    makeConnectMessage(item) {
      try {
        // 打診相談用(コネクト利用病院)
        if (item.properties.connect_mode && this.isLogin && this.myConnectStatus) {
          // 受け出しによって表記変える
          const modeMessage = this.connectModemessage(item);
          return `
          <div class="map-action-window-title-logo">
            <button class="map-action-window-ask" id="ask-connect-${item.properties.id}">
              <img src="img/cbj_logo_redC.svg" alt="logo" />${modeMessage} <img
                src="/img/icons/info-chevron-gray.svg"
                alt="info-chevron-right"
                class="map-action-window-ask-window"
              />
            </button>
          </div>
          `;
        }

        // PR用
        if (item.properties.connect_mode && this.isLogin && !this.myConnectStatus) {
          return `
          <div class="map-action-window-title-logo">
            <button class="map-action-window-ask" id="ask-web-${item.properties.id}">
              <div><img src="img/cbj_logo_redC.svg" alt="logo" />ウェブで相談
              </div>
            </button>
          </div>`;
        }
      } catch (err) {
        this.$sentry.captureException(err);
      }
    },
    makeEmptyBedinfoMessage(facility) {
      const hasPublicRelations = this.hasPublicRelationByFacilityId(facility.properties.id);
      const isEnabledPublicRelations = isPublicRelationsEnabled(
        this.isLogin,
        hasPublicRelations,
        facility.properties
      );

      if (isEnabledPublicRelations) {
        const infoButtonType = () => {
          if (this.selectedRadio === ASSET_TYPE.hospital) {
            return `<img src="/img/icons/info-bed.svg" alt="info-bed" class="station-list__bedicon" />空床情報をみる`;
          }
          if (
            this.selectedRadio === ASSET_TYPE.clinic ||
            this.selectedRadio === ASSET_TYPE.houkan
          ) {
            return `<img src="/img/icons/info-public-relations.svg" alt="public-relation-bed" class="station-list__bedicon" />情報をみる`;
          }
        };

        return `
        <div class="map-action-window-title-logo">
          <button class="map-action-window-bed" id="empty-bedinfo-${facility.properties.id}">
            ${infoButtonType()}
          </button>
        </div>
        `;
      }
      return '';
    },
    openConnectprDialog(item) {
      this.promotionItem = {
        properties: item.properties,
        analyticsUserId: this.makeAnalyticsLabel()
      };
      this.connectPrDialog = true;
      this.$analytics({
        // send Google Analytics
        eventCategory: 'map側ケアブックLP詳細を表示',
        eventAction: `(map側ケアブックLP詳細を表示)${item.properties.company_name}`,
        eventLabel: this.makeAnalyticsLabel()
      });
    },
    openConnectdetailDialog(item) {
      this.promotionItem = {
        properties: item.properties,
        analyticsUserId: this.makeAnalyticsLabel()
      };
      this.connectdetailDialog = true;
      this.$analytics({
        // send Google Analytics
        eventCategory: 'map側コネクト詳細を表示',
        eventAction: `(map側コネクト詳細表示)${item.properties.company_name}`,
        eventLabel: this.makeAnalyticsLabel()
      });
    },
    openHospitalPrDialog(bedData, facility) {
      if (!this.isLogin) return;

      this.publicRelationsInfoBaseProps = {
        companyName: facility.properties.company_name,
        lastUpdateAt: new Timestamp(bedData.updateAt.seconds, bedData.updateAt.nanoseconds),
        contactPersonName: bedData.name,
        tel: bedData.tel,
        fax: bedData.fax,
        telLabel: '直TEL',
        faxLabel: '直FAX'
      };

      this.hospitalPublicRelationsInfoSectionsProps = {
        description: bedData.description,
        ...bedData
      };

      this.connectEmptyBedInfoDialog = true; // ダイアログ表示
      this.$analytics({
        eventCategory: '詳細を表示',
        eventAction: `(詳細表示)${facility.properties.company_name}`,
        eventLabel: this.makeAnalyticsLabel()
      });
    },
    openVisitingNursePublicRelationsDialog(publicRelationsData, facility) {
      if (!this.isLogin) return;

      this.publicRelationsInfoBaseProps = {
        companyName: facility.properties.company_name,
        lastUpdateAt: new Timestamp(
          publicRelationsData.lastUpdateAt.seconds,
          publicRelationsData.lastUpdateAt.nanoseconds
        ),
        contactPersonName: publicRelationsData.contactPersonName,
        tel: publicRelationsData.tel,
        fax: publicRelationsData.fax,
        telLabel: DISPLAY_PHONE_DESK[publicRelationsData.telDesk],
        faxLabel: DISPLAY_FAX_DESK[publicRelationsData.faxDesk]
      };

      this.visitingNursePublicRelationsInfoSectionsProps = {
        weeklyAcceptablePersonPerDay: publicRelationsData.acceptablePersonPerDay,
        enrollmentType: publicRelationsData.enrollmentType,
        acceptablePatientType: publicRelationsData.acceptablePatientTypes,
        specializedInformationDescription: publicRelationsData.specializedInformationDescription,
        supportAreaDescription: publicRelationsData.supportAreaDescription,
        otherSupplementation: publicRelationsData.otherSupplementation
      };
      this.connectEmptyBedInfoDialog = true; // ダイアログ表示
      this.$analytics({
        eventCategory: '詳細を表示',
        eventAction: `(詳細表示)${facility.properties.company_name}`,
        eventLabel: this.makeAnalyticsLabel()
      });
    },
    openHomeMedicalPublicRelationsDialog(publicRelationsData, item) {
      if (!this.isLogin) return;

      this.publicRelationsInfoBaseProps = {
        companyName: item.properties.company_name,
        lastUpdateAt: new Timestamp(
          publicRelationsData.lastUpdateAt.seconds,
          publicRelationsData.lastUpdateAt.nanoseconds
        ),
        contactPersonName: publicRelationsData.contactPersonName,
        tel: publicRelationsData.tel,
        fax: publicRelationsData.fax,
        telLabel: DISPLAY_PHONE_DESK[publicRelationsData.telDesk],
        faxLabel: DISPLAY_FAX_DESK[publicRelationsData.faxDesk]
      };
      this.homeMedicalPublicRelationsInfoSectionsProps = {
        acceptablePatientDescription: publicRelationsData.acceptablePatientDescription,
        acceptablePatientType: publicRelationsData.acceptablePatientType,
        annexationOfHomeNursing: publicRelationsData.annexationOfHomeNursing,
        partnerMedicalInstitutionDescription:
          publicRelationsData.partnerMedicalInstitutionDescription,
        specializedFieldDescription: publicRelationsData.specializedFieldDescription,
        supportAreaDescription: publicRelationsData.supportAreaDescription
      };
      this.connectEmptyBedInfoDialog = true; // ダイアログ表示
      this.$analytics({
        eventCategory: '詳細を表示',
        eventAction: `(詳細表示)${item.properties.company_name}`,
        eventLabel: this.makeAnalyticsLabel()
      });
    },
    closeConnectdetailDialog() {
      this.connectdetailDialog = false;
    },
    closeConnectEmptyBedInfoDialog() {
      this.connectEmptyBedInfoDialog = false;
    },
    closeConnectprDialog() {
      this.connectPrDialog = false;
    },
    filterFacilityPublicRelationsData: function (facility) {
      const resultFaclityId = checkMappingFacilityId(facility.properties.id);

      const hospitalPrData = () => {
        return this.emptyBedinfoList.find((bedinfo) => bedinfo.clientId === resultFaclityId);
      };
      const publicRelationsPrData = () => {
        return this.publicRelationsData.find((prData) => prData.connectId === resultFaclityId);
      };

      if (this.selectedRadio === ASSET_TYPE.hospital) {
        this.openHospitalPrDialog(hospitalPrData(), facility);
      }
      if (this.selectedRadio === ASSET_TYPE.clinic) {
        this.openHomeMedicalPublicRelationsDialog(publicRelationsPrData(), facility);
      }
      if (this.selectedRadio === ASSET_TYPE.houkan) {
        this.openVisitingNursePublicRelationsDialog(publicRelationsPrData(), facility);
      }
    },
    hasPublicRelationByFacilityId: function (facilityId) {
      const resultFaclityId = checkMappingFacilityId(facilityId); // マッピングする施設IDがあるが確認する

      if (this.selectedRadio === ASSET_TYPE.hospital) {
        return this.emptyBedinfoList.some((bedinfo) => bedinfo.clientId === resultFaclityId);
      }

      if (this.selectedRadio === ASSET_TYPE.clinic || this.selectedRadio === ASSET_TYPE.houkan) {
        return this.publicRelationsData.some((prData) => prData.connectId === resultFaclityId);
      }
      return false;
    },
    openPrint: function () {
      const maplat = this.map.center.lat();
      const maplng = this.map.center.lng();
      const pos = `https://www.google.co.jp/maps/@${maplat},${maplng},16z?hl=ja`;
      this.$analytics({
        // send Google Analytics
        eventCategory: '一覧画面を表示',
        eventAction: pos,
        eventLabel: this.makeAnalyticsLabel()
      });
      window.open('/list', '_blank', 'noopener');
    },
    // filterBoundsはSearchToolbarに移行
    /**
     * 作成:ヒット件数上限超えたときのアラート
     */
    searchAlart: function () {
      if (this.boundsStation.length >= SEARCH_HIT_LIMIT) {
        this.searchAlartbar = true;
      } else {
        this.searchAlartbar = false;
      }
    },
    boundMarker: function (id) {
      // remove infobox windows
      this.clearInfoActionWindows();
      // マウスオーバー時にmapのmarkerをバウンドさせる
      this.markers.forEach((marker) => {
        if (!marker.content) {
          return;
        }
        // marker.contentは画像とラベルのdiv要素、markerをバウンスさせると文字までバウンスしてしまう
        // 画像だけバウンスさせたいので、画像のdiv要素を取得してバウンスさせる
        const targetDiv = marker.content.querySelector('div');
        if (targetDiv && targetDiv.classList.contains('marker-bounce')) {
          targetDiv.classList.remove('marker-bounce');
        } else {
          if (marker.facilityId === id) {
            targetDiv.classList.add('marker-bounce');
          }
        }
      });
    },
    makeAnalyticsLabel: function () {
      let label = this.selectedRadio;
      if (this.analyticsUserId !== '') {
        label += `, ${this.analyticsUserId}`;
      }
      return label;
    },
    connectModemessage: function (item) {
      if (item && item.properties.connect_mode) {
        switch (item.properties.connect_mode) {
          case CONNECT_MODE_MAP.RECEIVER: // 受け側
            return 'コネクトで打診ができます';
          case CONNECT_MODE_MAP.SENDER: // 出し側
            return '出し側の施設として、コネクトに参加しています';
          case CONNECT_MODE_MAP.BOTH: // 出し側と受け側の両方の場合は受け側の表記にしておく
            return 'コネクトで打診ができます';
          default:
            // 上のどれかに当てはまるはず
            return 'コネクトで打診ができます';
        }
      }
    },
    openGeolocationDialog: function () {
      this.geolocationDialog = true;
      setTimeout(() => {
        this.showGeolocationtips = false;
      }, 100);

      this.$analytics({
        // send Google Analytics
        eventCategory: '設定を表示',
        eventAction: '設定ボタンを押下',
        eventLabel: this.analyticsUserId
      });
    },
    closegeolocationDialog: function () {
      this.geolocationDialog = false;
    },
    setLatlng: function (item) {
      return {
        lat: item.geometry.coordinates[1],
        lng: item.geometry.coordinates[0]
      };
    }
  },
  computed: {
    ...mapGetters({
      // Vuexから施設データを取得
      boundsStation: 'features',
      selectedRadio: 'radios',
      currentLocation: 'location',
      markerId: 'mapMarker',
      isLogin: 'loginStatus',
      analyticsUserId: 'analyticsUserId',
      myConnectStatus: 'connectStatus',
      searchMapEvent: 'searchMap',
      enableAdminMode: 'adminMode',
      emptyBedinfoList: 'emptyBedinfo'
    }),
    isSearchHitlimit() {
      // 管理者の場合は表示する件数を変える
      return this.enableAdminMode
        ? (this.searchHitlimit = ADMIN_SEARCH_HIT_LIMIT)
        : (this.searchHitlimit = SEARCH_HIT_LIMIT);
    }
  },
  mounted: function () {
    this.initmap();
  }
};
