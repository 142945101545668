
import html2canvas from 'html2canvas';
import { mapGetters } from 'vuex';
import Bowser from 'bowser';
import { Loader } from '@googlemaps/js-api-loader';

const config = require('../settings/map');
const GA_EVENT_LABEL = '地域包括';

let mapDetail;
export default {
  props: {
    detailInfo: { type: Object, required: true }
  },
  data() {
    return {
      isIeEdge: false,
      isChrome: true,
      printButtonVisible: true,
      printButtonName: '印刷する',
      enableStaticMap: false,
      dialog: true
    };
  },
  computed: {
    ...mapGetters({
      analyticsUserId: 'analyticsUserId'
    })
  },
  methods: {
    initMap: function () {
      const MIN_ZOOM = 13; // 広く表示する最大値
      const MAX_ZOOM = 17;

      // MAP描画
      const loader = new Loader({
        apiKey: config.apiKey,
        version: 'quarterly',
        libraries: ['places']
      });
      loader.load().then((google) => {
        // MAPの表示設定
        const CAREBOOK_SEARCH_MAP_STYLE_ID = '78c257e071a1b966';
        const options = {
          mapId: CAREBOOK_SEARCH_MAP_STYLE_ID,
          center: this.detailInfo.latlng,
          zoom: 20,
          mapTypeControl: false,
          panControl: false,
          zoomControl: true,
          streetViewControl: false,
          fullscreenControl: false,
          isValidBrowser: '',
          keyboardShortcuts: false,
          minZoom: MIN_ZOOM, // 地図の表示エリア広くなる
          maxZoom: MAX_ZOOM // 地図の表示エリア狭くなる
        };

        mapDetail = new google.maps.Map(document.getElementById('area-detail-map'), options);
        this.editMarker();
      });
    },
    editMarker: async function () {
      const MARKER_PIN_ICON = '/img/location.svg';
      const pinContainer = document.createElement('div');
      const svgImg = document.createElement('img');
      svgImg.src = MARKER_PIN_ICON;
      svgImg.style.width = '56px';
      svgImg.style.height = '56px';
      pinContainer.appendChild(svgImg);

      const { AdvancedMarkerElement } = await google.maps.importLibrary('marker');
      new AdvancedMarkerElement({
        id: this.detailInfo.id,
        position: this.detailInfo.latlng,
        content: pinContainer,
        map: mapDetail
      });
    },
    doPrint() {
      /**
       * MsIE, MsEdgeは画像をダウンロードさせる
       * 他はprint()をそのまま使う
       */
      try {
        if (this.isValidBrowser !== 'Chrome') {
          this.createStaticMaps();
        }
        let label = '地域包括, 詳細ページ印刷';
        if (this.analyticsUserId !== '') {
          label += `, ${this.analyticsUserId}`;
        }
        this.$analytics({
          // send Google Analytics
          eventCategory: '詳細ページを印刷',
          eventAction: `(詳細ページ印刷)${this.detailInfo.company_name}`,
          eventLabel: label
        });
      } catch (err) {
        this.$sentry.captureException(err);
      }

      const downloadImage = (data) => {
        const fname = 'ケアブックの地域包括支援センター詳細ページ.png';
        const encdata = atob(data.replace(/^.*,/, ''));
        const outdata = new Uint8Array(encdata.length);
        for (let i = 0; i < encdata.length; i++) {
          outdata[i] = encdata.charCodeAt(i);
        }
        let blob = new Blob([outdata], ['image/png']);

        if (window.navigator.msSaveBlob) {
          window.navigator.msSaveOrOpenBlob(blob, fname);
        } else {
          document.getElementById('dl').href = data;
          document.getElementById('dl').click();
        }
        this.enableStaticMap = false;
      };

      const headerbtn = document.getElementsByClassName('v-dialog__headerbtn')[0];
      headerbtn.setAttribute('style', 'display:none');
      this.printButtonVisible = false;

      /**
       * google mapはそのままhtml2canvasしてはいけない
       * (利用規約違反だし、そもそもコードがうまく動かない)
       * chrome以外のときは、static map apiをつかって印刷時はその画像を表示する
       */
      if (this.isValidBrowser !== 'Chrome') {
        this.$nextTick(() => {
          html2canvas(document.querySelector('#detail-print-area'), {
            useCORS: true
          }).then(function (canvas) {
            downloadImage(canvas.toDataURL());
          });
          headerbtn.setAttribute('style', 'display:block');
          this.printButtonVisible = true;
        });
      } else {
        this.$nextTick(() => {
          window.print();
          headerbtn.setAttribute('style', 'display:block');
          this.printButtonVisible = true;
        });
      }
    },
    createStaticMaps: function () {
      const host = 'http://maps.googleapis.com/maps/api/staticmap?';
      let param = '&size=600x400&style=visibility:on&scale=2';
      param += `&center=${mapDetail.center.lat()},${mapDetail.center.lng()}&zoom=${mapDetail.zoom}`;
      param += `&key=${config.apiKey}`;
      param += `&markers=${this.detailInfo.latlng.lat},${this.detailInfo.latlng.lng}`;

      document.getElementById('area-detail-map__img').setAttribute('src', `${host}${param}`);
      this.enableStaticMap = true;
    }
  },
  watch: {
    dialog: {
      handler: function (val, oldVal) {
        if (this.dialog) {
          // this.initMap();
        } else {
          this.$emit('close');
        }
      },
      immediate: true // 初期表示時に実行させる
    }
  },
  mounted() {
    const bowser = Bowser.getParser(window.navigator.userAgent);
    this.isValidBrowser = bowser.getBrowserName();

    if (this.isValidBrowser === 'Microsoft Edge') {
      this.isIeEdge = true;
    }

    if (this.isValidBrowser !== 'Chrome') {
      // 印刷時の表示領域と一致させるために、mapのサイズを調整する
      this.$nextTick(() => {
        document.getElementById('area-detail-map').classList.add('area-detail-map__img');
      });
      this.printButtonName = 'この画面をダウンロードして印刷';
      this.printButtonVisible = true;
      this.isChrome = false;
    }
    setTimeout(() => {
      this.initMap();
      this.$analytics({
        // send Google Analytics
        eventCategory: '地域包括印刷画面を表示',
        eventAction: `${this.detailInfo.company_name}(tel: ${this.detailInfo.tel})`,
        eventLabel: GA_EVENT_LABEL
      });
    }, 200);
  }
};
